<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <app-logo-spinner></app-logo-spinner>
  <ng-container>
    <div class="no-internet-container" *ngIf="status === 'OFFLINE'">
      <div class="no-internet-image-container">
        <img src="assets/icon/no-internet.png" alt="">
      </div>
      <div class="no-internet-message">
        <h1>Oops!</h1>
        <div>
          <span>It seems there is something wrong with your</span>
          <span>internet connection. Please connect to the</span>
          <span>internet and refresh again</span>
        </div>
      </div>
      <div class="no-internet-footer">
        <button (click)="refreshPage()"
                mat-raised-button color="primary">Try Again
        </button>
      </div>
    </div>
  </ng-container>
  <pwa-install
          name="Sandhyadeep"
          manifest-url="assets/pwa-manifest/manifest.json"
          description="Book your appointments seamlessly online & Elevate your beauty experience with Sandhyadeep"
          icon="https://app.sandhyadeep.in/assets/theme-images/Sandhyadeep_logo.png"
  ></pwa-install>
</ion-app>