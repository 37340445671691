<ion-menu [type]="'push'" contentId="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-title>Menu Content</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list *ngIf="userRole && userRole === 'admin'">
      <ion-menu-toggle autoHide="false">
        <ion-item [routerLink]="['/admin/admin-service-types']">
          <ion-label>
            Service Types
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/admin-sub-types']">
          <ion-label>
            Service Sub-Types
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/admin-services']">
          <ion-label>
            Services
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/admin-users']">
          <ion-label>
            Users
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/admin-packages']">
          <ion-label>
            Packages
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/image-assets']">
          <ion-label>
            Assets
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/service-images']">
          <ion-label>
            Service Images
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/admin/coupons']">
          <ion-label>
            Coupons
          </ion-label>
        </ion-item>
        <ion-item [routerLink]="['/feed']">
          <ion-label>
            Check Your Changes
          </ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>
<div class="ion-page" id="main-content">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-menu-button></ion-menu-button>
      </ion-buttons>
      <div style="display: flex; align-items: center; justify-content: space-between; padding: 0 12px 0 0;">
        <div style="display: flex; align-items: center">
          <div style="width: 65px; overflow: hidden; display: inline-block; margin: 5px 0;">
            <img src="/assets/theme-images/Sandhyadeep_logo.png">
          </div>
          <div class="logo-container">
            <div class="logo-text">
              Sandhyadeep
            </div>
            <div class="slogan-text">
              Have a hair affair with us
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div [routerLink]="['/settings']" class="profileIcon">
            <img src="assets/icon/profile-icon.png" alt="">
          </div>
        </div>
      </div>
    </ion-toolbar>
    <div class="filters-container">
      <div class="search-box gap-3 d-flex align-items-center px-3">
        <ion-searchbar (ionClear)="clearSearch()" class="p-0" [(ngModel)]="searchString" [placeholder]="communicationService.placeHolder | async"></ion-searchbar>
        <button (click)="onSearch(searchString)" mat-mini-fab color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>search</mat-icon>
        </button>
<!--        <button (click)="onAdd()" mat-mini-fab color="primary" aria-label="Example icon button with a home icon">-->
<!--          Add <mat-icon>add</mat-icon>-->
<!--        </button>-->
        <button *ngIf="communicationService.pageTitle | async" (click)="onAdd()" color="primary" mat-raised-button class="text-uppercase">
          Add
        </button>
      </div>
    </div>
    <div *ngIf="communicationService.pageTitle | async" class="page-heading">
      <div class="heading-text">
        {{communicationService.pageTitle | async}}
      </div>
    </div>
    <ion-progress-bar *ngIf="communicationService.showAdminSpinner | async" type="indeterminate"></ion-progress-bar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-router-outlet></ion-router-outlet>
  </ion-content>
</div>
