// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ACCESS_TOKEN_KEY: 'my-access-token',
  REFRESH_TOKEN_KEY: 'my-refresh-token',
  USER_INFO: 'user-info',
  SELECTED_SERVICES: 'selected-services',
  SELECTED_PACKAGES: 'selected-packages',
  // RazorPayConfiguration
  RAZORPAY: {
    KEY: 'rzp_test_uPxCJhIQrZ46rn',
    SECRET: 'R9NUbTbYQ3CRsK2flKo47J3b',
    ORGANIZATION: '',
    LOGO: '/assets/new-theme/sandhyadeep-razerpay.png',
    COLOR: '',
    DESCRIPTION: '',
    NOTES: '',
    CURRENCY: 'INR'
  },

  // baseAuthUrl: 'http://localhost:3001/api/v1/auth/',
  // baseURLAdminAPIs: 'http://localhost:3001/api/v1/',
  // domainUrlApi: 'http://localhost:3001/api/',
  baseAuthUrl: 'https://www.sandhyadeep.in/api/v1/auth/',
  baseURLAdminAPIs: 'https://www.sandhyadeep.in/api/v1/',
  domainUrlApi: 'https://www.sandhyadeep.in/api/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
